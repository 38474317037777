// generated by recipeyak.api.base.codegen
import { http } from "@/apiClient"

export function sectionDelete(params: { section_id: number }) {
  return http<unknown>({
    url: "/api/v1/sections/{section_id}/",
    method: "delete",
    params,
    pathParamNames: ["section_id"],
  })
}
