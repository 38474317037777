// generated by recipeyak.api.base.codegen
import { http } from "@/apiClient"

export function noteDelete(params: { note_id: string }) {
  return http<unknown>({
    url: "/api/v1/notes/{note_id}/",
    method: "delete",
    params,
    pathParamNames: ["note_id"],
  })
}
