// generated by recipeyak.api.base.codegen
import { http } from "@/apiClient"

export function cookChecklistRetrieve(params: { recipe_id: number }) {
  return http<Record<string, boolean>>({
    url: "/api/v1/cook-checklist/{recipe_id}/",
    method: "get",
    params,
    pathParamNames: ["recipe_id"],
  })
}
