// generated by recipeyak.api.base.codegen
import { http } from "@/apiClient"

/**
 * Calls Django Auth SetPasswordForm save method.
 *
 * Accepts the following POST parameters: new_password1, new_password2
 * Returns the success/fail message.
 */
export function userPasswordUpdate(params: {
  old_password: string
  new_password1: string
  new_password2: string
}) {
  return http<{
    detail: string
  }>({
    url: "/api/v1/auth/password/change/",
    method: "post",
    params,
  })
}
