// generated by recipeyak.api.base.codegen
import { http } from "@/apiClient"

export function calendarList(params: { start: string; end: string }) {
  return http<{
    scheduledRecipes: Array<{
      id: number
      created: string
      createdBy: {
        id: number
        name: string
        avatar_url: string
      } | null
      team: number | null
      user: number | null
      recipe: {
        id: number
        name: string
        author: string | null
        archivedAt: string | null
        primaryImage: {
          id: string
          url: string
          backgroundUrl: string | null
        } | null
      }
      on: string
    }>
  }>({
    url: "/api/v1/calendar/",
    method: "get",
    params,
  })
}
