// generated by recipeyak.api.base.codegen
import { http } from "@/apiClient"

export function stepUpdate(params: {
  text?: string | null
  position?: string | null
  step_id: number
}) {
  return http<{
    id: number
    text: string
    position: string
  }>({
    url: "/api/v1/steps/{step_id}/",
    method: "patch",
    params,
    pathParamNames: ["step_id"],
  })
}
