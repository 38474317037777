// generated by recipeyak.api.base.codegen
import { http } from "@/apiClient"

/**
 * Check the credentials and login if credentials are valid and authenticated.
 * Calls Django Auth login method to register User ID in Django session framework.
 *
 * Accept the following POST parameters: username, password
 */
export function userLogin(params: { email: string; password: string }) {
  return http<{
    user: {
      id: number
      name: string
      avatar_url: string
      email: string
      theme_day: "light" | "dark" | "dark_dimmed" | "autumn" | "solarized"
      theme_night: "light" | "dark" | "dark_dimmed" | "autumn" | "solarized"
      theme_mode: "single" | "sync_with_system"
      schedule_team: number | null
    }
  }>({
    url: "/api/v1/auth/login/",
    method: "post",
    params,
  })
}
