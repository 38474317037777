// generated by recipeyak.api.base.codegen
import { http } from "@/apiClient"

export function sectionCreate(params: {
  position?: string | null
  title: string
  recipe_id: number
}) {
  return http<{
    id: number
    title: string
    position: string
  }>({
    url: "/api/v1/recipes/{recipe_id}/sections",
    method: "post",
    params,
    pathParamNames: ["recipe_id"],
  })
}
