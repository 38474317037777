// generated by recipeyak.api.base.codegen
import { http } from "@/apiClient"

export function userUpdate(params: {
  email?: string | null
  name?: string | null
  schedule_team?: number | null
  theme_day?: "light" | "autumn" | "solarized" | "dark" | "dark_dimmed" | null
  theme_night?: "light" | "autumn" | "solarized" | "dark" | "dark_dimmed" | null
  theme_mode?: "single" | "sync_with_system" | null
}) {
  return http<{
    id: number
    name: string
    avatar_url: string
    email: string
    theme_day: "light" | "dark" | "dark_dimmed" | "autumn" | "solarized"
    theme_night: "light" | "dark" | "dark_dimmed" | "autumn" | "solarized"
    theme_mode: "single" | "sync_with_system"
    schedule_team: number | null
  }>({
    url: "/api/v1/user/",
    method: "patch",
    params,
  })
}
