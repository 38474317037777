// generated by recipeyak.api.base.codegen
import { http } from "@/apiClient"

export function memberUpdate(params: {
  level: "admin" | "contributor" | "read"
  member_id: number
}) {
  return http<{
    id: number
    created: string
    level: "admin" | "contributor" | "read"
    user: {
      id: number
      name: string | null
      avatar_url: string
      email: string
    }
  }>({
    url: "/api/v1/members/{member_id}/",
    method: "patch",
    params,
    pathParamNames: ["member_id"],
  })
}
