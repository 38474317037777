// generated by recipeyak.api.base.codegen
import { http } from "@/apiClient"

export function noteCreate(params: {
  text: string
  attachment_upload_ids: ReadonlyArray<string>
  recipe_id: number
}) {
  return http<{
    id: string
    text: string
    created_by: {
      id: number
      name: string
      email: string
      avatar_url: string
    }
    created: string
    modified: string
    attachments: Array<{
      id: string
      url: string
      backgroundUrl: string | null
      contentType: string
      isPrimary: boolean
      type: "upload"
    }>
    reactions: Array<{
      id: string
      type: "❤️" | "😆" | "🤮"
      note_id: number
      user: {
        id: number
        name: string
        email: string
        avatar_url: string
      }
      created: string
    }>
    type: "note"
  }>({
    url: "/api/v1/recipes/{recipe_id}/notes/",
    method: "post",
    params,
    pathParamNames: ["recipe_id"],
  })
}
