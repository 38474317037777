// generated by recipeyak.api.base.codegen
import { http } from "@/apiClient"

export function teamDelete(params: { team_id: number }) {
  return http<unknown>({
    url: "/api/v1/t/{team_id}/",
    method: "delete",
    params,
    pathParamNames: ["team_id"],
  })
}
