// generated by recipeyak.api.base.codegen
import { http } from "@/apiClient"

export function sessionList() {
  return http<
    Array<{
      id: string
      device: {
        kind: "mobile" | "desktop" | null
        os: string | null
        browser: string | null
      }
      last_activity: string
      ip: string | null
      current: boolean
    }>
  >({
    url: "/api/v1/sessions/",
    method: "get",
  })
}
