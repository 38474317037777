// generated by recipeyak.api.base.codegen
import { http } from "@/apiClient"

export function reactionDelete(params: { reaction_id: string }) {
  return http<unknown>({
    url: "/api/v1/reactions/{reaction_id}/",
    method: "delete",
    params,
    pathParamNames: ["reaction_id"],
  })
}
