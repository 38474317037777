// generated by recipeyak.api.base.codegen
import { http } from "@/apiClient"

export function stepDelete(params: { step_id: number }) {
  return http<unknown>({
    url: "/api/v1/steps/{step_id}/",
    method: "delete",
    params,
    pathParamNames: ["step_id"],
  })
}
