// generated by recipeyak.api.base.codegen
import { http } from "@/apiClient"

export function userCommentsList(params: { user_id: string }) {
  return http<{
    comments: Array<{
      recipe: {
        id: number
        name: string
      }
      note: {
        id: string
        text: string
        created_by: {
          id: number
          name: string
          email: string
          avatar_url: string
        }
        created: string
        modified: string
        attachments: Array<{
          id: string
          url: string
          backgroundUrl: string | null
          contentType: string
          isPrimary: boolean
          type: "upload"
        }>
        reactions: Array<{
          id: string
          type: "❤️" | "😆" | "🤮"
          note_id: number
          user: {
            id: number
            name: string
            email: string
            avatar_url: string
          }
          created: string
        }>
        type: "note"
      }
    }>
  }>({
    url: "/api/v1/user/{user_id}/comments/",
    method: "get",
    params,
    pathParamNames: ["user_id"],
  })
}
